import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import styles from '../../styles/profile/editProfile.module.css';
import ding_token from '../../images/v1/home/ding_token.png';
import { editUser, saveUser } from "../../ApiCalls/Profile/api_v1_profile";
import { useNavigate } from "react-router-dom";
// import SfxMainAudio from "../AutoPlayAudio/SfxMainAudio";
import Cookies from 'universal-cookie';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import jwt_decode from "jwt-decode";
import leftArrow from "../../images/v1/icons/left_arrow_white.png";
import threeDots from "../../images/v1/icons/three_dots.svg"
import logoutImg from "../../images/v1/profile/logout.png";
import editImg from "../../images/v1/profile/edit.png";

// import profileAltImg from "../../images/v1/profile/profile_alt.png";

function EditProfile() {
    const cookies = new Cookies();
    let navigate = useNavigate();
    let tel = useRef();
    let coins = useRef();
    const isLoading = useRef(0);
    const [isDisabled, setIsDisabled] = useState(false);
    const nameRef = useRef(null);
    let uname = useRef();
    const emailRef = useRef(null);
    const showUnsubscribe = useRef(false);
    let [showSideBar, setShowSideBar] = useState(false);
    const storedAvatar = localStorage.getItem("avatar");
    const [avatar, setAvatar] = useState(storedAvatar !== null ? storedAvatar : ding_token);
    // State to manage the selected gender
    const [gender, setGender] = useState("") // Initialize with an empty string

    // Function to handle changes in the gender radio buttons
    const handleGenderChange = (event) => {
        setGender(event.target.value)
    };
    const items = [
        <div className={styles.imgWrap} style={{ width: "120px", margin: "5px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src={ding_token} alt="" />
        </div>
    ]
    const [carouselItems, setCarouselItems] = useState(items); // Initialize with hardcoded items
    const activeSlide = useRef(0); // State to keep track of the active slide index
    const handleDragStart = (e) => e.preventDefault();
    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#191919";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#191919");
        document.title = "Edit Profile | Ding";
    }, []);
    useEffect(() => {
        try {
            const accessToken = localStorage.getItem('token');
            const decoded = jwt_decode(accessToken);
            const msisdn = String(decoded.userInfo['msisdn']);
            uname.current.innerHTML = msisdn;
            if (msisdn.substring(0, 2) === "92") {
                showUnsubscribe.current = true;
            } else {
                showUnsubscribe.current = false;
            }

        } catch (error) {
            showUnsubscribe.current = false;
        }
        editUser().then(response => {
            const accessToken = response.headers['access_token'];
            if (accessToken != null) {
                localStorage.setItem('token', accessToken);
            }

            // const valuesArray = JSON.parse(response.data);
            // let result = text.slice(0, 5);
            let data = response.data.data;
            let avatars = data.avatars;
            // Map over the avatars array and create carousel items
            const avatarItems = avatars.map((avatar, index) => (
                <div key={"carousel-" + index} className={styles.imgWrap} data-uuid={avatar.uuid}>
                    <img src={avatar.avatar_url} alt="loading..." />
                </div>
            ));
            setCarouselItems(avatarItems);
        })
            .catch(error => {
                console.log(error);
                // console.log(error.response.status,error.response.data.message);
            })
    }, [])

    function handleClick() {
        navigate('/home');
    }
    function editProfile() {
        navigate('/edit-profile');
    }

    const responsiveItems = {
        0: {
            items: 1,
        }
    }
    const handleInitialized = () => {
        const nextButton = document.querySelector('.alice-carousel__next-btn-item span[data-area]');
        const prevButton = document.querySelector('.alice-carousel__prev-btn-item span[data-area]');
        if (prevButton) {
            prevButton.removeAttribute('data-area');
        }
        if (nextButton) {
            nextButton.removeAttribute('data-area');
        }
    };
    const handleSlideChanged = (e) => {
        const activeSlideIndex = e.slide;

        // Get the active slide element using the class name of alice-carousel__stage-item
        const activeSlideElement = document.querySelector('.alice-carousel__stage-item.__active .editProfile_imgWrap__D2HQc');

        // Check if the activeSlideElement is available before accessing its data-uuid attribute
        // if (activeSlideElement) {
        // Get the UUID from the data attribute of the active slide element
        const activeSlideUUID = activeSlideElement.getAttribute('data-uuid');
        activeSlide.current = activeSlideUUID;
        // }
    };

    function handleSubmit(event) {
        event.preventDefault(); // Prevent the default form submission behavior
        // Get the form data
        setIsDisabled(true);
        const button = event.target;
        // Save the original styles
        const originalBorderStyle = button.style.border;
        const originalColorStyle = button.style.color;
        // Modify the styles
        button.style.border = '2px solid gray';
        button.style.color = 'gray';
        button.innerHTML = "Please Wait"
        const formData = {
            "name": nameRef.current.value,
            "email": emailRef.current.value,
            "gender": gender,
            "dpuuid": activeSlide.current
        };


        saveUser(formData).then(response => {
            setIsDisabled(false);
            button.style.border = originalBorderStyle;
            button.style.color = originalColorStyle;
            const accessToken = response.headers['access_token'];
            if (accessToken != null) {
                localStorage.setItem('token', accessToken);
            }

            if (response.status === 200) {
                button.innerHTML = "Profile Updated";
                setTimeout(() => {
                    navigate("/profile")
                }, 1000);

            }

        })
            .catch(error => {
                // console.log(error);
            })
    }
    function handleUnsub() {
        navigate("/unsubscribe");
    }
    function navigateProfile() {
        navigate("/profile");
    }
    function toggleSideBar() {
        setShowSideBar(!showSideBar);
    }
    function handleLogout() {
        localStorage.removeItem('token');
        localStorage.removeItem('ctx');
        localStorage.removeItem('dx');
        localStorage.removeItem('time');
        cookies.remove('uid');
        cookies.remove('rmsid');
        const nextYear = new Date();
        const currentD = new Date();
        nextYear.setFullYear(currentD.getFullYear() + 1);
        cookies.set('uid_exp', 1, { path: '/', expires: nextYear });
        // return navigate('/login');
        const redirectURL = process.env.REACT_APP_SESSION_URL + "/?logout=1";
        window.location.href = redirectURL;
        return;
    }
    return (
        <>

            <div className={`mt-3 ml-3 w-full d-flex align-items-center justify-content-between`} >
                <div className={`mt-3 ml-3 d-flex cursor-pointer`} onClick={navigateProfile}>
                    <img className={styles.leftArrowImg} src={leftArrow} alt="..." /><p className={`ml-3 text-white`}>Back to profile</p>
                </div>
                <img className={`mx-3 cursor-pointer`} src={threeDots} alt="Three dots" onClick={toggleSideBar} />
            </div>
            <div id={styles.sidebar} className={showSideBar ? styles.show : ""} >
                <ul className="list-unstyled components mt-4">
                    <li className='d-flex align-items-center d-flex align-items-center justify-content-between'>
                        <div></div>
                        <p className={`text-white cursor-pointer`} onClick={toggleSideBar}>X</p>
                    </li>
                    <li className={`mt-5 d-flex align-items-center ${styles.listItem}`}>
                        <img className={`mx-3 ${styles.leftArrowImg}`} src={avatar} alt="..." />
                        <div className="d-flex flex-column">
                            {/* <p className={`text-white mb-0`}></p> */}
                            <p className="mt-1 mb-0 small" ref={uname}></p>
                        </div>
                    </li>
                    {showUnsubscribe.current &&
                        <li className='mt-4 d-flex align-items-center cursor-pointer' onClick={handleLogout}>
                            <img className={`mx-3 ${styles.leftArrowImg}`} src={logoutImg} alt="..." />
                            <p className={`text-white`}>Logout</p>
                        </li>}
                    {showUnsubscribe.current &&
                        <li className='mt-4 d-flex align-items-center cursor-pointer' onClick={handleUnsub}>
                            <img className={`mx-3 ${styles.leftArrowImg}`} src={logoutImg} alt="..." />
                            <p className={`text-white`}>Unsubscribe</p>
                        </li>
                    }
                </ul>
            </div>
            <main className={styles.main}>
                <section className={styles.profileSection}>
                    <div id={styles.editProfileAliceCarouselWrapper} className="editProfileAliceCarouselWrapper">
                        <AliceCarousel
                            touchTracking={false}
                            mouseTracking={false}
                            responsive={responsiveItems}
                            disableDotsControls={true}
                            infinite={true}
                            items={carouselItems} // Use the dynamic carouselItems here
                            onInitialized={handleInitialized}
                            onSlideChanged={handleSlideChanged} // Assign the function to onSlideChanged prop
                        >
                        </AliceCarousel>
                    </div>
                </section>
                <section className={styles.formSection}>
                    <form className={styles.editForm}>
                        <div className='mt-2'>
                            <p className='mb-2'>Name</p>
                            <input className="formInputPorfile " type="text" placeholder='Name' name='name' required="true" ref={nameRef} />
                        </div>
                        <div className='mt-3'>
                            <p className='mb-2'>Email</p>
                            <input className="formInputPorfile " type="text" placeholder='Email' name='email' required="true" ref={emailRef} />
                        </div>
                        <div className='d-flex' style={{ justifyContent: "flex-start" }}>
                            <div id={styles.genderWraper}>
                                <div className="form-check">
                                    <input className="form-check-input form-check-input2" type="checkbox" value="male" id="male" checked={gender === 'male'} onChange={handleGenderChange} />
                                    <label className="form-check-label text-white" htmlFor="male">
                                        Male
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input form-check-input2" type="checkbox" value="female" id="female" checked={gender === 'female'} onChange={handleGenderChange} />
                                    <label className="form-check-label text-white" htmlFor="female">
                                        Female
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input form-check-input2" type="checkbox" value="other" id="other" checked={gender === 'other'} onChange={handleGenderChange} />
                                    <label className="form-check-label text-white" htmlFor="other">
                                        Prefer not to say
                                    </label>
                                </div>
                            </div>
                        </div>
                        <button type='submit' className={`${styles.backToVideos} mt-3`} onClick={handleSubmit} disabled={isDisabled}>Save Profile</button>
                    </form>
                    {/* {showUnsubscribe.current &&
                        <section className={styles.buttonSection}>
                            <button type='button' className={styles.backToVideos} onClick={handleUnsub}>Unsubscribe</button>
                        </section>
                    } */}
                </section>
            </main>
        </>
    )
}

export default React.memo(EditProfile);