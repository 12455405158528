import  axiosClient  from "../axiosClient";

export function getHeaders(){
    return axiosClient.get("/fetchHeader");
}

export function otpSend(data){
    return axiosClient.post('/login', JSON.stringify(data));
}

export function otpValidate(data){
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post('/validate', JSON.stringify(data));
}

export function subscribeWifi(){
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post('/wifiSubscription');
}

export function unsubscribe(data){
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post('/unsub-web',JSON.stringify(data));
}

export function logout(data){
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post('/logout',JSON.stringify(data));
}


export function getEncMsiUnsub(data) {
    return axiosClient.post('https://unsubscribe.jazz.ding.pk/api/encrypt', JSON.stringify(data));
}

// const bearerTokenHeader = {
        
//     headers: { "Authorization": `Bearer ${infoState.AccessToken}` }
// };