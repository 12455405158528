import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Routes, Route, } from "react-router-dom";
// import { useEffect } from 'react';
import GoogleAnalytics from './components/analytics/GoogleAnalyticsConditional';
import Login from './components/login/Login';
import Home from "./components/home/Home";
// import HomeWithoutSplash from "./components/home/Home";
// import SidebarBottom from "./components/home/SidebarBottom";
// import Header from './components/header/Header';
import Footer from "./components/footer/Footer";
import RedeemHeader from "./components/redeem/RedeemHeader";
import RedeemBody from "./components/redeem/RedeemBody";
import SavedVideos from "./components/savedVideos/SavedVideos";
import Categories from "./components/savedVideos/Categories";
import Profile from "./components/profile/Profile";
import QuestionTime from "./components/question/QuestionTime";
import Rewards from "./components/reward/Rewards";
import ThankYou from './components/redeem/ThankYou';
import ProtectedRoutes from './components/Auth/Protected';
import WatchSaved from './components/savedVideos/watchSaved';
import WatchSharedVideo from './components/SharedVideo/WatchShareVideo';
import EditProfile from './components/profile/EditProfile';
import Unsub from './components/Unsubscribe/Unsub';
import "./TimeRecorder/TimeRecorder";

function App() {
  return (
    <div>
      <GoogleAnalytics
        trackingId="G-5N4XGKLXTT"  // DING JAZZ ID for the login page
      />
      <Routes>
        <Route path="/login" element={<><Login /></>}> </Route>
        <Route element={<ProtectedRoutes />}>
          <Route path="/" element={<> <Home showSplash={true} /><Footer /> </>}> </Route>
          <Route path="/home" element={<> <Home showSplash={true} /><Footer /> </>}> </Route>
          <Route path="/home/landing" element={<> <Home showSplash={false} /><Footer /> </>}> </Route>
          <Route path="/redeem" element={<> <RedeemHeader /><RedeemBody /> </>}> </Route>
          <Route path="/profile" element={<> <Profile /><Footer /> </>}> </Route>
          <Route path="/edit-profile" element={<> <EditProfile /><Footer /> </>}> </Route>
          <Route path="/saved" element={<> <SavedVideos /> <Footer /></>}> </Route>
          <Route path="/categories" element={<> <Categories /> <Footer /></>}> </Route>
          <Route path="/shared/video" element={<> <WatchSharedVideo /><Footer /></>}> </Route>
          <Route path="/saved/watch" element={<> <WatchSaved /><Footer /></>}> </Route>
          <Route path="/trivia" element={<> <QuestionTime /><Footer /> </>}> </Route>
          <Route path="/rewards" element={<><Rewards /><Footer /></>}> </Route>
          <Route path="/thankyou" element={<><ThankYou /></>}> </Route>
          <Route path="/unsubscribe" element={<><Unsub /></>}> </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;